import Storage from "@/utils/storagehandle";

const homeData = {
  namespaced: true,
  state: {
    devData: Storage.getItem("devData"),
    alarmData: Storage.getItem("alarmData"),
    probeData: Storage.getItem("probeData"),
    hostNum: Storage.getItem("hostNum"),
    probesNum: Storage.getItem("probesNum"),
    alarmNum: Storage.getItem("alarmNum"),
  },
  mutations: {
    SET_DEV_DATA: (state, data) => {
      Storage.setItem("devData", data);
      state.devData = data;
      Storage.setItem("hostNum", data.length);
      state.hostNum = data.length;
    },
    SET_ALARM_DATA: (state, data) => {
      Storage.setItem("alarmData", data);
      state.alarmData = data;
      Storage.setItem("alarmNum", data.length);
      state.alarmNum = data.length;
    },
    SET_PROBE_DATA: (state, data) => {
      Storage.setItem("probeData", data);
      state.probeData = data;
      Storage.setItem("probesNum", data.length);
      state.probesNum = data.length;
    },
  },
};

export default homeData;
