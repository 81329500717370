<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <el-scrollbar class="my-scroll-bar">
          <router-view :key="key" />
        </el-scrollbar>
      </keep-alive>
    </transition>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AppMain",
  computed: {
    ...mapState({
      //theme: (state) => state.setting.theme,
    }),
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  height: calc(100vh - 60px);
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f0f1f5;
}

.fixed-header + .app-main {
  padding-top: 60px;
}

.hasTagsView {
  .app-main {
    min-height: calc(100vh - 94px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
