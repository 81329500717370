import Storage from '@/utils/storagehandle'

const mapData = {
  state: {
    currentMap: Storage.getItem('currentMap'),
    // devLatlonArray: Storage.getItem('devLatlonArray')
    hostDevArray: Storage.getItem('hostDevArray'), 
  },
  mutations: {
    SET_CURRENT_MAP: (state, data) => {
      Storage.setItem('currentMap', data)
      state.currentMap = data
    },
    // SET_DEV_LATLON_ARRAY: (state, data) => {
    //   Storage.setItem('devLatlonArray', data)
    //   state.devLatlonArray = data
    // },
    SET_HOTS_DEV_ARRAY: (state, data) => {
      Storage.setItem('hostDevArray', data)
      state.hostDevArray = data
    } 
  },
}

export default mapData
