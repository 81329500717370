const fixArrData = {
  state: {
    onlineStatusList: [
      {
        value: undefined,
        label: '全部',
      },
      {
        value: 0,
        label: '离线',
      },
      {
        value: 1,
        label: '在线',
      },
    ],
    /** 气体类型 */
    gasTypeList: [
      {
        value: 1,
        label: '可燃气（Ex）',
      },
      {
        value: 2,
        label: '氧气（O2）',
      },
      {
        value: 3,
        label: '一氧化碳（CO）',
      },
      {
        value: 4,
        label: '二氧化碳（CO2）',
      },
      {
        value: 5,
        label: '二氧化硫（SO2）',
      },
      {
        value: 6,
        label: '氯气（CL2）',
      },
      {
        value: 7,
        label: '氨气（NH3）',
      },
      {
        value: 8,
        label: '氢气（H2）',
      },
      {
        value: 9,
        label: '氰化氢（HCN）',
      },
      {
        value: 10,
        label: '氯化氢（HCl）',
      },
      {
        value: 11,
        label: '臭氧（O3）',
      },
      {
        value: 12,
        label: '硫化氢（H2S）',
      },
      {
        value: 13,
        label: '一氧化氮（NO）',
      },
      {
        value: 14,
        label: '二氧化氮（NO2）',
      },
      {
        value: 15,
        label: '环氧乙烷（C2H4O）',
      },
      {
        value: 16,
        label: '二氧化氯（CLO2）',
      },
      {
        value: 17,
        label: 'VOC',
      },
    ],
    /** 报警 */
    alarmTypeList: [
      {
        value: 0,
        label: '丢失',
      },
      {
        value: 1,
        label: '正常',
      },
      {
        value: 2,
        label: '低报',
      },
      {
        value: 3,
        label: '高报',
      },
      {
        value: 4,
        label: '故障',
      },
    ],
    alarmRuleList: [
      {
        value: 1,
        label: '高报',
      },
      {
        value: 2,
        label: '自定义',
      },
    ],
    alarmStatusList: ['丢失', '正常', '低报', '高报', '故障', '屏蔽'],
    alarmTypeArray: [
      {
        value: undefined,
        label: '全部',
      },
      {
        value: 0,
        label: '丢失',
      },
      //{
      //  value: 1,
      //  label: "正常",
      //},
      {
        value: 2,
        label: '低报',
      },
      {
        value: 3,
        label: '高报',
      },
      {
        value: 4,
        label: '故障',
      },
      {
        value: 5,
        label: '屏蔽',
      },
      {
        value: 6,
        label: '控制器故障',
      },
      {
        value: 7,
        label: '传感器故障',
      },
    ],
    controllerAlarmTypeArray: [
      {
        value: -1,
        label: '全部',
      },
      {
        value: 1,
        label: '充电异常',
      },
      {
        value: 2,
        label: '通讯异常',
      },
      {
        value: 3,
        label: '硬件异常',
      },
      {
        value: 4,
        label: '主电异常',
      },
      {
        value: 7,
        label: '备电异常',
      },
      // {
      //   value: 9,
      //   label: "高报",
      // },{
      //   value: 10,
      //   label: "低报",
      // },
      {
        value: 11,
        label: '离线',
      },
    ],
    decetorAlarmTypeArray: [
      {
        value: -1,
        label: '全部',
      },
      {
        value: 0,
        label: '丢失',
      },
      // {
      //   value: 1,
      //   label: "正常",
      // },
      {
        value: 2,
        label: '低报',
      },
      {
        value: 3,
        label: '高报',
      },
      // {
      //   value: 4,
      //   label: "故障",
      // },
      // {
      //   value: 5,
      //   label: "屏蔽",
      // },
      {
        value: 6,
        label: '硬件异常',
      },
      {
        value: 7,
        label: '超限故障',
      },
      {
        value: 8,
        label: '传感器故障',
      },
    ],
    alarmStateList: ['关', '开'],
    faultStateList1: ['无', '故障'],
    faultStateList2: ['无', '报警'],
    /** 设备 */
    devTypeArray: ['消防液晶控制器', '四合一便携'],
    devTypeList: [
      {
        value: 0,
        label: '消防液晶控制器',
      },
      {
        value: 1,
        label: '四合一便携',
      },
    ],
    /** 通知 */
    switchList: [
      {
        prop: 'sms',
        label: '短信通知',
      },
      {
        prop: 'voice',
        label: '电话通知',
      },
      {
        prop: 'mail',
        label: '邮箱提醒',
      },
    ],
    /** 数据 */
    // dataStateList: [
    //   {
    //     value: 0,
    //     label: '正常',
    //   },
    //   {
    //     value: 1,
    //     label: '低报',
    //   },
    //   {
    //     value: 2,
    //     label: '高报',
    //   },
    // ],
    dataAlarmStateList: [
      {
        value: 0,
        label: '关',
      },
      {
        value: 1,
        label: '开',
      },
    ],
    dataDevLineList: [
      {
        value: 0,
        label: '离线',
      },
      {
        value: 1,
        label: '在线',
      },
    ],
    // 精度
    measurementPrecisioList: [1, 0.1, 0.01, 0.001],
    alarmmodelList: ['非氧气模式', '氧气模式'],
  },
}

export default fixArrData
