import Cookies from "js-cookie";
const app = {
    state: {
        sidebar: {
            opened: Cookies.get("sidebarStatus") ?
                !!+Cookies.get("sidebarStatus") : true,
            // withoutAnimation: false,
        },
        identifyCode: '',
        speechObj: {},
        probeInAlarm: Cookies.get("probeInAlarm") ? Number(Cookies.get("probeInAlarm")) : 0,
        speechStatus: Cookies.get("speechStatus") ? Number(Cookies.get("speechStatus")) : 0,
        isMicrophone: Cookies.get("isMicrophone") ? Number(Cookies.get("isMicrophone")) : 0,
    },
    mutations: {
        TOGGLE_SIDEBAR: (state) => {
            state.sidebar.opened = !state.sidebar.opened;
            state.sidebar.withoutAnimation = false;
            if (state.sidebar.opened) {
                Cookies.set("sidebarStatus", 1);
            } else {
                Cookies.set("sidebarStatus", 0);
            }
        },
        CLOSE_SIDEBAR: (state) => {
            Cookies.set("sidebarStatus", 0);
            state.sidebar.opened = false;
            // state.sidebar.withoutAnimation = withoutAnimation;
        },
        CLOSE_SPEECH: (state, data) => {
            //alert(data)
            if (data) data = 1
            else data = 0
            Cookies.set("speechStatus", data);
            Cookies.set("isMicrophone", data);
            state.speechStatus = data;
            state.isMicrophone = data;
            // state.sidebar.withoutAnimation = withoutAnimation;
        },
        TOGGLE_PROBEINALARM: (state, data) => {
            //alert(data)
            Cookies.set("probeInAlarm", data);
            state.probeInAlarm = data;
            // state.sidebar.withoutAnimation = withoutAnimation;
        },
        SET_CODE: (state, data) => {
            state.identifyCode = data
        },
        SET_SPEECHOBJ: (state, data) => {
            state.speechObj = data
        }
    },
    actions: {
        toggleSideBar({ commit }) {
            commit("TOGGLE_SIDEBAR");
        },
        toggleProbeInAlarm({ commit }, status) {
            commit("TOGGLE_PROBEINALARM", status);
        },
        closeSideBar({ commit }, { withoutAnimation }) {
            commit("CLOSE_SIDEBAR", withoutAnimation);
        },
        closeSpeech({ commit }, status) {
            commit("CLOSE_SPEECH", status);
        },
    },
};

export default app;