import Storage from '@/utils/storagehandle'
const deviceData = {
  state: {
    deviceDetailData: Storage.getItem('deviceDetailData'),
  },
  mutations: {
    SET_DEVICE_DETAIL_DATA: (state, data) => {
      state.deviceDetailData = data
      Storage.setItem('deviceDetailData', data)
    },
  },
  actions: {},
}

export default deviceData
