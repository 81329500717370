const dataData = {
  state: {
    alarmSearchData: {},
    controllerSearchData: {},
    decetorSearchData: {},
    historySearchData: {},
    historyIdData: null,
    controllerRecordSearchData: {},
    decetorRecordSearchData: {},
    reveiceData: {},
  },
  mutations: {
    SET_ALARM_SEARCH_DATA: (state, data) => {
      state.alarmSearchData = data;
    },
    SET_CONTROLLER_SEARCH_DATA: (state, data) => {
      state.controllerSearchData = data;
    },
    SET_DECETOR_SEARCH_DATA: (state, data) => {
      state.decetorSearchData = data;
    },
    SET_HISTORY_SEARCH_DATA: (state, data) => {
      state.historySearchData = data;
    },
    SET_HISTORY_ID_DATA: (state, data) => {
      state.historyIdData = data
    },
    SET_CONTROLLER_RECORD_SEARCH_DATA: (state, data) => {
      state.controllerRecordSearchData = data;
    },
    SET_DECETOR_RECORD_SEARCH_DATA: (state, data) => {
      state.decetorRecordSearchData = data;
    },
    FAN_REVEICE_DATA: (state, data) => {
      state.reveiceData = data;
    },
  },
};

export default dataData;
