<!-- 导航 -->
<template>
  <div class="navbar" ref="navbar">
    <div class="left-menu" style="line-height: 60px">
      <!-- 折叠按钮 -->
      <hamburger
        id="hamburger-container"
        :is-active="sidebar.opened"
        class="hamburger-container"
        @toggleClick="toggleSideBar"
      />
      <div v-if="title === '设备详情'" class="title">
        <el-button type="text" icon="el-icon-arrow-left" @click="goBack"
          >返回</el-button
        >
        <!-- <span>{{ title }}</span> -->
      </div>
      <!-- <div v-else class="title">
        <span>{{ title }}</span>
      </div> -->
    </div>

    <div class="right-menu">
      <!--消除音频 图标-->
      <!-- <span class="microphone header-btn" @click="toggleMicrophone">
        <el-tooltip
          class="item"
          effect="dark"
          :content="(isMicrophone ) ? '关闭消音' : '消音'"
          placement="bottom"
        >
        <img class="icon-size" :src="isMicrophone ? iconClassList[0] : iconClassList[1]" />
        </el-tooltip>
      </span> -->
      <!-- <i :class="isMicrophone ? 'el-icon-turn-off-microphone' : 'el-icon-mic'"></i> -->
      <!--全屏显示 图标，点击全屏 ;消除音频-->
      <!-- <span class="screenfull header-btn" @click="toggleFullScreen">
        <el-tooltip
          class="item"
          effect="dark"
          :content="isFullScreen ? '退出全屏' : '全屏'"
          placement="bottom"
        >
          <i :class="isFullScreen ? 'el-icon-aim' : 'el-icon-full-screen'"></i>
        </el-tooltip>
      </span> -->
      <!-- 报警器开关 -->
      <!-- <el-tooltip effect="dark"
                  content="报警器开关"
                  placement="bottom">
        <el-button class="right-menu-btn"
                   type="text"
                   >
          <img src="@/assets/images/app_images/alarm.png"
               alt="图片碎了" />
        </el-button>
      </el-tooltip>-->

      <!-- 二维码 -->
      <!-- <el-tooltip effect="dark"
                  content="二维码"
                  placement="bottom">
        <el-button class="right-menu-btn"
                   type="text"
                   >
          <img src="@/assets/images/app_images/qrcode.png"
               alt="图片碎了" />
        </el-button>
      </el-tooltip>-->

      <!-- 消息 -->
      <!-- <el-tooltip effect="dark"
                  content="消息"
                  placement="bottom">
        <el-button class="right-menu-btn"
                   type="text"
                   >
          <img src="@/assets/images/app_images/msg.png"
               alt="图片碎了" />
        </el-button>
      </el-tooltip>-->

      <!-- 文档中心 -->
      <!-- <el-tooltip effect="dark"
                  content="文档中心"
                  placement="bottom">
        <el-button class="right-menu-btn"
                   type="text"
                   >
          <img src="@/assets/images/app_images/document.png"
               alt="图片碎了" />
        </el-button>
      </el-tooltip>-->
      <!--切换主题-->
      <!-- <theme-picker @change="themeChange"></theme-picker> -->
      <!-- 返回首页 -->
      <el-tooltip effect="dark" content="返回首页" placement="bottom">
        <el-button class="right-menu-btn" type="text" @click="backHome">
          <img src="@/assets/images/app_images/home.png" alt="图片碎了" />
        </el-button>
      </el-tooltip>

      <!-- 用户 -->
      <!-- <el-tooltip effect="dark" content="用户" placement="bottom">
        <el-button class="right-menu-btn" type="text" @click="userOption">
          <img
            src="@/assets/images/app_images/headportrait.png"
            alt="图片碎了"
          />
        </el-button>
      </el-tooltip>-->

      <el-dropdown
        trigger="click"
        style="margin-left: 10px"
        class="user-dropdown"
      >
        <el-button class="right-menu-btn user-btn" type="text" @click="userOption">
          <img
            src="@/assets/images/app_images/headportrait.png"
            alt="图片碎了"
          />
          <span>{{ token.nickName }}</span>
        </el-button>
        <!-- <el-button
          class="right-menu-btn"
          type="text"
          style="color: rgb(2, 20, 82)"
          >{{ token.nickName }}</el-button
        > -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <template>
              <div class="drop-content" style="width: 200px">
                <ul>
                  <li>
                    <label for class="nospecial-font">用户名：</label>
                    <span>{{ token.nickName }}</span>
                  </li>
                  <li>
                    <label for class="nospecial-font">用户姓名：</label>
                    <span>{{ token.name }}</span>
                  </li>
                  <!-- <li>
                    <label for="" class="nospecial-font">公司：</label
                    ><span>{{ token.company.name }}</span>
                  </li>-->
                  <li>
                    <label for class="nospecial-font">公司/部门：</label>
                    <span>{{ token.department.name }}</span>
                  </li>
                  <li>
                    <label for class="nospecial-font">邮箱：</label>
                    <span>{{ token.email }}</span>
                  </li>
                  <li>
                    <label for class="nospecial-font">手机：</label>
                    <span>{{ token.phone }}</span>
                  </li>
                </ul>
                <hr />
                <div style="display: flex; justify-content: space-between">
                  <el-button
                    type="primary"
                    size="small"
                    class="user"
                    @click="goUser"
                    >个人中心</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    class="user"
                    @click="goPassword"
                    >修改密码</el-button
                  >
                </div>
                <el-button class="logout" @click.native="handleLogout"
                  >退出</el-button
                >
              </div>
            </template>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <!--全屏显示 图标，点击全屏 ;消除音频-->
      <el-tooltip
        class="item"
        effect="dark"
        :content="isFullScreen ? '退出全屏' : '全屏'"
        placement="bottom"
      >
        <el-button
          class="right-menu-btn"
          :icon="isFullScreen ? 'el-icon-aim' : 'el-icon-full-screen'"
          type="text"
          @click="toggleFullScreen"
        ></el-button>
        <!-- <i :class="isFullScreen ? 'el-icon-aim' : 'el-icon-full-screen'"></i> -->
      </el-tooltip>

      <!--消除音频 图标-->
      <el-tooltip
        class="item"
        effect="dark"
        :content="isMicrophone ? '关闭消音' : '消音'"
        placement="bottom"
      >
        <el-button class="right-menu-btn" type="text" @click="toggleMicrophone">
          <img
            class="icon-size"
            :src="isMicrophone ? iconClassList[0] : iconClassList[1]"
          />
        </el-button>
      </el-tooltip>
    </div>
    <!-- 新增修改密码弹窗 -->
    <el-dialog
      title="修改密码"
      @before-close="Form = {}"
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form ref="Form" :rules="rules" :model="Form" label-width="auto">
        <el-form-item prop="oldPwd" label="原始密码：">
          <el-input
            type="password"
            v-model="Form.oldPwd"
            placeholder="请输入原始密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="newPwd" label="新密码：">
          <el-input
            type="password"
            v-model="Form.newPwd"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="rePwd" label="确认密码：">
          <el-input
            type="password"
            v-model="Form.rePwd"
            placeholder="请输入确认密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="sure" type="primary" @click="sure('Form')"
            >确 定</el-button
          >
          <!--<el-button @click="cancel('Form')">取 消</el-button>-->
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 新增修改密码弹窗 -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Hamburger from "@/components/Hamburger";
// import ThemePicker from "@/components/ThemePicker";
// import Cookies from "js-cookie";
import { editPwd, logout } from "@/api/app";
export default {
  data() {
    return {
      canFullScreen: false,
      isFullScreen: false,
      //theme:Cookies.get("theme") ? Cookies.get("theme") : '#021452',
      title: "",
      iconClassList: [
        require("/src/assets/iconfont/voice_close.svg"),
        require("/src/assets/iconfont/voice_on.svg"),
      ],
      rules: {
        oldPwd: [
          { required: true, message: "请输入原始密码", trigger: "blur" },
        ],
        newPwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        rePwd: [{ required: true, message: "请输入确认密码", trigger: "blur" }],
      },
      dialogVisible: false,
      Form: {
        department: {},
      },
    };
  },
  components: {
    Hamburger,
    // ThemePicker
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      speechObj: (state) => state.app.speechObj,
      // speechStatus: (state) => state.app.speechStatus,
      isMicrophone: (state) => state.app.isMicrophone,
    }),
    ...mapGetters(["sidebar"]),
  },
  watch: {
    $route(route) {
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getData();
    },
    // speechStatus: {
    //   handler: function (val) {
    //     //if(val || val==='true') this.isMicrophone = !val
    //     //
    //   },
    //   immediate: true,
    // },
  },
  // computed: {
  //   routes() {
  //     return this.$store.state.permission.routes;
  //   },
  // },
  created() {
    this.getData();
    this.initInfo();
  },
  destroyed() {
    document.onkeydown = null;
    switch (this.browserKernel) {
      case "webkit":
        document.onwebkitfullscreenchange = null;
        break;
      case "gecko":
        document.onmozfullscreenchange = null;
        break;
      case "trident":
        document.onmsfullscreenchange = null;
        break;
      case "others":
        document.onfullscreenchange = null;
        break;
      default:
        break;
    }
  },
  methods: {
    openSpeech() {
      //this.$store.dispatch("closeSpeech", 0);
      //speechSynthesis.cancel(this.speechObj);
      //this.isMicrophone = false
    },
    themeChange(val) {
      this.$store.dispatch("changeSetting", { key: "theme", value: val });
      //this.theme = val
    },
    toggleMicrophone() {
      if (this.isMicrophone) {
        if (!this.speechObj || this.speechObj === undefined) {
          this.speechObj = new SpeechSynthesisUtterance();
          this.$store.commit("SET_SPEECHOBJ", this.speechObj);
        }
        this.$store.dispatch("closeSpeech", 0);
        //this.speechObj.volume = 1;
        speechSynthesis.resume();
        //speechSynthesis.cancel(this.speechObj);
        //this.isMicrophone = false
      } else {
        if (!this.speechObj || this.speechObj === undefined) {
          this.speechObj = new SpeechSynthesisUtterance();
          this.$store.commit("SET_SPEECHOBJ", this.speechObj);
        }
        this.$store.dispatch("closeSpeech", 1);
        //this.speechObj.volume = 0;
        speechSynthesis.pause();
        //speechSynthesis.cancel(this.speechObj);
        //this.isMicrophone = true
      }
    },
    initInfo() {
      // 检查浏览器是否支持全屏
      this.canFullScreen =
        document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled;
      if (document.webkitFullscreenEnabled) {
        this.browserKernel = "webkit";
      } else if (document.mozFullScreenEnabled) {
        this.browserKernel = "gecko";
      } else if (document.msFullscreenEnabled) {
        this.browserKernel = "trident";
      } else if (document.fullscreenEnabled) {
        this.browserKernel = "others";
      }
      if (this.canFullScreen) {
        this.addFullScreenListener();
      }
    },
    toggleFullScreen() {
      if (this.canFullScreen) {
        if (this.isFullScreen) {
          // 关闭全屏
          this.exitFullScreen();
          this.isFullScreen = false;
        } else {
          // 打开全屏
          this.requestFullScreen(document.body);
          this.isFullScreen = true;
        }
      } else {
        this.$message.warning({
          content: "当前浏览器暂不支持全屏模式，请切换浏览器后重新尝试！",
          duration: 3,
        });
      }
    },
    requestFullScreen(element) {
      // 判断各种浏览器，找到正确的方法
      const requestMethod =
        element.requestFullScreen || // W3C
        element.webkitRequestFullScreen || // Chrome, safari
        element.mozRequestFullScreen || // FireFox
        element.msRequestFullscreen; // IE11
      if (requestMethod) {
        requestMethod.call(element);
      }
    },
    exitFullScreen() {
      var exitMethod =
        document.exitFullscreen || // W3C
        document.mozCancelFullScreen || // FireFox
        document.webkitExitFullscreen || // Chrome等
        document.msExitFullscreen; // IE11
      if (exitMethod) {
        exitMethod.call(document);
      }
    },
    addFullScreenListener() {
      const self = this;
      document.onkeydown = function (e) {
        if (e && e.keyCode === 122) {
          // 捕捉F11键盘动作
          e.preventDefault(); // 阻止F11默认动作
          self.toggleFullScreen();
        }
      };
      // 监听不同浏览器的全屏事件，并件执行相应的代码
      switch (self.browserKernel) {
        case "webkit":
          document.onwebkitfullscreenchange = function () {
            if (document.webkitIsFullScreen) {
              self.isFullScreen = true;
            } else {
              self.isFullScreen = false;
            }
          };
          break;
        case "gecko":
          document.onmozfullscreenchange = function () {
            if (document.mozFullScreen) {
              self.isFullScreen = true;
            } else {
              self.isFullScreen = false;
            }
          };
          break;
        case "trident":
          document.onmsfullscreenchange = function () {
            if (document.msFullscreenElement) {
              self.isFullScreen = true;
            } else {
              self.isFullScreen = false;
            }
          };
          break;
        case "others":
          document.onfullscreenchange = function () {
            if (document.fullscreen) {
              self.isFullScreen = true;
            } else {
              self.isFullScreen = false;
            }
          };
          break;
        default:
          break;
      }
    },
    toggleSideBar() {
      this.$store.dispatch("toggleSideBar");
    },
    goBack() {
      this.$router.push({ path: "/device-manage/index" });
    },
    getData() {
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
      this.title = matched[0].meta.title;
    },
    goUser() {
      this.$router.push({ path: "/account-manage/user-center" });
    },
    goPassword() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.Form.clearValidate();
      })
    },
    /** 返回首页 */
    backHome() {
      this.$router.push({ path: "/" });
      // this.$router.push({ path: "/home" });
    },
    /** 用户 */
    userOption() {
      // this.$router.push({ path: "/login" });
      // this.$router.push({ path: "/" });
      this.$store.commit("REMOVE_TOKEN");
    },
    handleLogout() {
      logout().then(() => {
        this.$store.dispatch("user/logout");
        location.reload();
      });
    },
    /** 确定 */
    async sure(formName) {
      const params = {
        oldPwd: this.Form.oldPwd,
        newPwd: this.Form.newPwd,
      };
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          editPwd(params).then((res) => {
            if (res.code === 0) {
              that.dialogVisible = false;
              that.$notify.success("修改成功");
              that.$store.dispatch("user/logout");
              location.reload();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  height: 60px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .title {
    // position: absolute;
    // left: 0;
    display: inline-block;
    line-height: 56px;
    height: 100%;
    // float: left;
    // margin-left: 10px;
    font-weight: 700;
  }

  .right-menu {
    // float: right;
    // position: absolute;
    // right: 150px;
    height: 100%;
    line-height: 60px;
    margin-right: 30px;
    // .add-btn {
    //   line-height: 46px;
    // }
    .right-menu-btn {
      padding: 0;
      vertical-align: middle;
      background-color: #fff !important;
      border-color: #fff !important;
      margin-right: 5px;
    }
    .user-btn {
      img, span {
        vertical-align: middle;
      }
      img {
        margin-right: 10px;
      }
      span {
        color: #333333;
      }
    }
    .icon-size {
      width: 25px;
      height: 25px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.drop-content {
  width: 200px;
  ul {
    list-style: none;
    padding: 0;
    li {
      .nospecial-font {
        font-size: 0.75rem /* 12/16 */;
        color: #777777;
      }
    }
  }
}

.hamburger-container {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.screenfull {
  //width: 35px;
  height: 60px;
  float: right;
  line-height: 70px;
  margin-left: 10px;
  cursor: pointer;
  display: block;
}
.microphone {
  //width: 35px;
  height: 60px;
  float: right;
  line-height: 70px;
  margin-left: 5px;
  cursor: pointer;
  display: block;
}
</style>
<style lang="scss">
.el-icon-full-screen,
.el-icon-aim {
  font-size: 25px;
  color: rgb(2, 20, 82);
}
.header-btn {
  .el-icon-full-screen,
  .el-icon-aim,
  .el-icon-mic,
  .el-icon-turn-off-microphone {
    font-size: 25px;
    color: rgb(2, 20, 82);
  }
}
.logout {
  width: 100%;
  margin-left: 0;
  margin-top: 5px !important;
}

.user {
  text-align: center;
  margin-bottom: 5px;
}
</style>
