import Vue from "vue";

import Cookies from "js-cookie";

import "normalize.css/normalize.css";

import Element from "element-ui";
import "./styles/element-variables.scss";

import Router from "vue-router";

// import "amfe-flexible";

import "@/styles/index.scss";
import "@/styles/font.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission";

import * as filters from "./utils/filters";

// import "./assets/iconfont/iconfont.css"; //引入阿里巴巴矢量图标库

Vue.use(Element, {
    size: Cookies.get("size") || "medium",
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});
//禁用页面错误信息
Vue.config.productionTip = true;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");