<!-- Logo -->
<template>
  <div class="sidebar-logo-container"
       :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link v-if="!collapse"
                   key="collapse"
                   class="sidebar-logo-link"
                   to="/">
        <img :src="logo"
             alt="图片碎了"
             class="sidebar-logo" />
      </router-link>
    </transition>

    <!-- 折叠按钮 -->
    <!-- <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Hamburger from "@/components/Hamburger";
export default {
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  // components: {
  //   Hamburger,
  // },
  data() {
    return {
      logo: require("@/assets/images/login_images/logo.png"),
    };
  },
  // computed: {
  //   ...mapGetters(["sidebar"]),
  // },
  // methods: {
  //   toggleSideBar() {
  //     this.$store.dispatch("toggleSideBar");
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
  // transition-duration: 1.5s;
}

.sidebar-logo-link {
  display: inline-block;
  vertical-align: middle;
  .sidebar-logo {
    width: 10.825rem /* 130/16 */;
  }
}

.sidebar-logo-container {
  padding: 0.9375rem /* 15/16 */ 0;
  text-align: center;
  height: 60px;
}

// .collapse {
//   padding: 0.9375rem /* 15/16 */ 0;
// }

// .hamburger-container {
//   display: inline-block;
//   vertical-align: middle;
//   cursor: pointer;
// }
</style>
