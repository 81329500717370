<template>
  <div id="app">
    <router-view />
    <el-button
      v-show="nots.length > 0"
      style="
        position: absolute;
        bottom: 10px;
        right: 50px;
        width: 60px;
        height: 60px;
      "
      type="primary"
      circle
      @click="test"
      >清除</el-button
    >
  </div>
</template>

<script>
import axios from 'axios'
// import layout from "./layout/index.vue";
import { mapState } from 'vuex'
import { getProbeAlarm } from '@/api/app'
//import Cookies from "js-cookie";

export default {
  name: 'App',
  data() {
    return {
      //theme:Cookies.get("theme") ? Cookies.get("theme") : '#409EFF',
      // colorList: [
      //   "lost-color",
      //   "normal-color",
      //   "low-color",
      //   "height-color",
      //   "super-color",
      //   "mask-color",
      // ],
      notifyColorList: [
        'notify-lost',
        'notify-normal',
        'notify-low',
        'notify-height',
        'notify-super',
        'notify-mask',
      ],
      ncontent: '',
      speechObj: {},
      nots: [],
      notsCount: 0,
      timer: null,
      timerCount : 0,
      msg2 : {},
      msg3 : {},
      // i: 0,
      // flag: false,
    }
  },
  mounted() {
    this.initws()
    if (this.token !== null) {
      this.getBeingAlarm()
      // if (!this.isMicrophone) {
      //   this.$store.dispatch("closeSpeech", 1);
      //   speechSynthesis.pause();
      // }
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      speechStatus: (state) => state.app.speechStatus,
      alarmStatusList: (state) => state.fixArrData.alarmStatusList,
      isMicrophone: (state) => state.app.isMicrophone,
      probeInAlarm: (state) => state.app.probeInAlarm,
    }),
    defaultTheme() {
      return this.$store.state.settings.theme
        ? this.$store.state.settings.theme
        : this.theme
    },
  },
  watch: {
    // token() {
    //   this.wsLogin()
    // },
    probeInAlarm: {
      handler: function (Val) {
        let probeInAlarm = localStorage.getItem('probeInAlarm') //本地存储上次报警个数
        /*智能静音的处理*/
        if (probeInAlarm !== undefined) {
          if (Val > probeInAlarm) {
            // console.log('播放声音1')
            this.$store.dispatch('closeSpeech', 0)
            // this.readPlay()
            speechSynthesis.resume()
          }
        } else {
          if (Val > 0) {
            // console.log('播放声音2')
            this.$store.dispatch('closeSpeech', 0)
            // this.readPlay()
            speechSynthesis.resume()
          }
        }
        localStorage.setItem('probeInAlarm', Val)
      },
      immediate: true,
    },
    defaultTheme: {
      handler: function (val) {
        this.theme = val
      },
      immediate: true,
    },
  },
  methods: {
    /** 获取正在报警 */
    getBeingAlarm() {
      const params = {
        state: -1,
      }
      getProbeAlarm(params).then((res) => {
        if (res.code === 0) {
          this.$store.dispatch('toggleProbeInAlarm', res.data.length)
        }
      })
    },
    wsLogin() {
      this.speechObj = new SpeechSynthesisUtterance()
      this.$store.commit('SET_SPEECHOBJ', this.speechObj)

      this.socket.send(
        JSON.stringify({
          cmd: 1,
          user: this.token,
        })
      )
    },
    resetTimer(){
      if(this.timer != null){
        clearInterval(this.timer)
        this.timer = null;
        this.timerCount = 0;
      }
      this.timer = setInterval(() => {
        this.$store.commit('FAN_REVEICE_DATA', this.msg2.dataMessage)
        this.getBeingAlarm() //获取报警个数
        console.log("timers:" + this.timerCount)
        this.timerCount++
        if(this.timerCount > 3){
          clearInterval(this.timer)          
          this.timer = null
          this.timerCount = 0;
        }
      }, 3000);

    },
    initws() {
      const socketUrl =
        (window.location.protocol === 'https' ? 'wss' : 'ws') +
        '://' +
        window.location.host +
        '/devDataMessage'

      if (axios.defaults.baseURL === '/') {
        this.socketUrl = socketUrl
      } else {
        // this.socketUrl = 'ws://192.168.0.143:8888/devDataMessage'
        this.socketUrl = 'WS://' + location.host + '/socket/devDataMessage'
      }

      this.socket = new WebSocket(this.socketUrl)
      this.socket.onopen = () => {
        // Web Socket 已连接上，使用 send() 方法发送数据
        // this.socket.send('Holle')
        if (this.token) {
          this.wsLogin()
        }
      }
       var that = this
      this.socket.onmessage = (evt) => {
        const msg = JSON.parse(evt.data)
        console.log(msg)
        
        switch (msg.cmd) {
          case 2:
            this.msg2 = msg;
            that.$store.commit('FAN_REVEICE_DATA', msg.dataMessage)
            that.getBeingAlarm() //获取报警个数
            that.resetTimer()
            break
          case 3:
            that.resetTimer()
            this.msg3 = msg;
            var h = that.$createElement
            let state = msg.alarmData.state
            let data = msg.alarmData.data;
            if(msg.alarmData.overfault == "true"){
              state = 4
            }else if(msg.alarmData.sensorfault == "true"){
              state = 4
            }else if(msg.alarmData.hardfault == "true"){
              state = 4
            }
            if (state === 4) {
              data = '-'
            } else if (state === 0) {
              data = '----'
            } 
            this.ncontent =
              msg.alarmData.dev.name +
              '(编号:' +
              msg.alarmData.dev.code +
              ')的通道' +
              msg.alarmData.probe.passage +
              '(' +
              msg.alarmData.probe.gases +
              ',位置：' +
              msg.alarmData.probe.addr +
              ',时间: ' +
              msg.alarmData.updateDate +
              ') \n 状态：' +
              this.alarmStatusList[state] +
              '。显示值：' +
              data +
              ' ' +
              msg.alarmData.probe.unit;
            let not = {
              message: h('div', { class: 'aaa' }, [
                h('span', { class: 'not-content' }, that.ncontent),
              ]),
              duration: 0,
              position: 'bottom-right',
              offset: 70,
              dangerouslyUseHTMLString: true,
              customClass: that.notifyColorList[state],
              
              onClose: function (res) {
                if(that.notsCount > 0){
                  that.notsCount--
                  if(that.notsCount == 0){
                    that.nots = []
                  }
                }
                /*
                if (that.nots.length === 1 || that.i === that.nots.length - 1) {
                  that.nots = []
                  that.i = 0
                  localStorage.setItem('nots', JSON.stringify(that.nots))
                  localStorage.setItem('notsNUm', that.i )
                }
                that.i++
                console.log('nots',that.nots)
                console.log('notsi',that.i)
                */
              },
            }
            let rst = that.$notify(not)
            that.nots.push(rst)
            that.notsCount++;
            /*
            let newnots = localStorage.getItem('nots') //本地存储上次数据
            let notsNUm = localStorage.getItem('notsNUm') //本地存储上次数据
            if(newnots && newnots!== undefined) that.nots = JSON.parse(newnots);
            if(notsNUm && notsNUm!== undefined) that.i = notsNUm;
            if(that.nots != undefined){
              that.nots.push(rst)
              
              try{
                let str = JSON.stringify(that.nots)
                localStorage.setItem('nots', str)
                localStorage.setItem('notsNUm', that.i)
              }catch(e){
                console.log(e)
              }
            }*/
            that.readPlay()
        }
      }
      this.socket.onclose = function () {
        if(this.timer != null){
          clearInterval(this.timer)
          this.timer = null;
          this.timerCount = 0;
        }
        // 关闭 websocket
        console.log('连接已关闭...')
      }
      // // 路由跳转时结束websocket链接
      // this.$router.afterEach(function () {
      //   this.socket.close()
      // })
    },
    test() {
      var that = this
      this.nots.forEach((element) => {
        element.close()
        //that.flag = true
      })
      this.nots = [];
    },
    readPlay() {
      let u = this.speechObj //new SpeechSynthesisUtterance();
      u.text = '探测器发生异常，请注意！'
      u.lang = 'zh'
      u.rate = 1
      let n = 0
      speechSynthesis.speak(u)
      while (n < 10) {
        speechSynthesis.speak(u)
        n++
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
.dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
}
.not-content {
  white-space: pre-line;
}
.lost-color {
  background-color: $lostColor;
}
.normal-color {
  background-color: $normalColor;
}
.low-color {
  background-color: $lowColor;
}
.height-color {
  background-color: $heightColor;
}
.super-color {
  background-color: $superColor;
}
.mask-color {
  background-color: $lostColor;
}
</style>

<style lang="scss">
@import '@/styles/index.scss';
.notify-lost {
  background-color: $lostColor !important;
}
.notify-normal {
  background-color: $normalColor !important;
}
.notify-low {
  background-color: $lowColor !important;
}
.notify-height {
  background-color: $heightColor !important;
}
.notify-super {
  background-color: $superColor !important;
}
.notify-mask {
  background-color: $lostColor !important;
}
</style>
