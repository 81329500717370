import router from "./router";
import store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({
  showSpinner: false,
});

const whiteList = [
  "/login",
  "/register",
  "/retrieve-first",
  "/verific-device",
  "/retrieve-first",
  "/retrieve-second",
];

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const hasToken = store.state.user.token;
  if (hasToken) {
    if (to.path === "/login") {
      next({
        path: "/",
      });
      NProgress.done();
    } else {
      next();
      NProgress.done();
    }
    if (to.meta.title) {
      document.title = to.meta.title;
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/login");
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
