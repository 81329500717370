const accountData = {
  state: {
    userSearchData: {},
    contactSearchData: {},
  },

  mutations: {
    'SET_USER_SEARCH_DATA': (state, data) => {
      state.userSearchData = data
    },
    'SET_CONTACT_SEARCH_DATA': (state, data) => {
      state.contactSearchData = data
    },
  },
}

export default accountData
