import Storage from "@/utils/storagehandle";
// import { login } from '@/api/app'
const user = {
  namespaced: true,
  state: {
    token: Storage.getItem("token"),
  },
  mutations: {
    SET_TOKEN: (state, value) => {
      state.token = value;
    },
    // REMOVE_TOKEN: () => {
    //   Storage.removeItem("token");
    // },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve) => {
        // return new Promise((resolve, reject) => {
        // login(data)
        //   .then((res) => {
        //     commit("SET_TOKEN", res.object);
        //     Storage.setItem("token", res.object);
        //     // location.reload();
        //     resolve(res.object);
        //   })
        //   .catch((error) => {
        //     reject(error);
        //   });
        commit("SET_TOKEN", data.object);
        Storage.setItem("token", data.object);
        location.reload();
        resolve(data.object);
      });
    },
    logout({ commit }) {
      commit("SET_TOKEN", "");
      Storage.removeItem("token");
    },
  },
};

export default user;
