import axios from 'axios'
import qs from 'qs'
import { Notification } from 'element-ui'
import store from '@/store'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = '/api'
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = '/'
}

axios.interceptors.request.use(
  (config) => {
    if (store.state.user.token) {
      config.headers.common.token = store.state.user.token
    }

    if (config.data && Object.keys(config.data).length == 0) {
      delete config.data
    }
console.log(config)
    return config
  },

  (error) => {
    Notification.notify({
      type: 'error',
      message: '加载超时',
      duration: 5000,
      offset: 70,
    })
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code && res.code !== 0) {
      Notification({
        type: 'error',
        message: res.state || res.msg,
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    if (error.status) {
      switch (error.status) {
        case 401:
          Storage.removeItem('token')
          window.location = '/login'
          break
        case 403:
          // console.log(222)
          Notification({
            type: 'error',
            message: '登录过期，请重新登录',
            duration: 5000,
            offset: 70,
          })
          Storage.removeItem('token')
          window.location = '/login'
          break
        case 404:
          // console.log(333)
          Notification({
            type: 'error',
            message: '网络请求不存在',
            duration: 5000,
            offset: 70,
          })
          break
        default:
          // console.log(444)
          Notification({
            type: 'error',
            message: error.msg,
            duration: 5000,
            offset: 70,
          })
          break
      }
    } else {
      if (JSON.stringify(error).includes('timeout')) {
        // console.log(555)
        Notification({
          type: 'error',
          message: '服务器响应超时，请刷新当前页面',
          duration: 5000,
          offset: 70,
        })
      }
    }

    return Promise.reject(error)
  }
)

export function get(url, params) {
  // console.log(url)
  // console.log(params)
  return new Promise((resolve, reject) => {
    axios
      .get(url + '?' + qs.stringify(params, { allowDots: true }))
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        // console.log(err)
        reject(err)
      })
  })
}
export function qget(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url + '?' + qs.stringify(params, { allowDots: true }))
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function qpost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(params, { allowDots: true }))
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function qapost(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        qs.stringify(params, { arrayFormat: 'indices', allowDots: true })
      )
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function qput(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        url,
        qs.stringify(params, { arrayFormat: 'indices', allowDots: true })
      )
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function remove(url, params) {
  // console.log(url)
  // console.log(params)
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { params: params })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default axios
