import { get, post, qapost, qget, qpost, qput, remove } from '@/utils/request'

/** 报警统计 */
export const alarmStatisticsProbe = (p) => get('/alarmStatistics/probe', p)
/** 报警参数 */
export const getAlarmParm = (p) => get('/alarmParm', p)
export const addAlarmParm = (p) => qpost('/alarmParm', p)
export const putAlarmParm = (p) => qput('/alarmParm', p)
export const removeAlarmParm = (p) => remove('/alarmParm', p)

/** 报警设备 */
export const getAlarmAD = (p) => get('/ad', p)

/** 报警数据 */
export const getAlarmData = (p) => get('/alarmData', p)
/** 后台缺少逻辑 */
export const getAlarmDataCount = (p) => get('/alarmData/count', p)
export const getAlarmProbesNum = (p) => get('/alarmData/probeCount', p)
export const getStateProbesNum = (p) => get('/alarmData/stateCount', p)
export const addAlarmData = (p) => qpost('/alarmData', p)
export const putAlarmData = (p) => qput('/alarmData', p)
export const removeAlarmData = (p) => remove('/alarmData', p)

// /** 报警类型 之前注释的 */
// export const getAlarmType = (p) => get('/alarmType', p)
// export const addAlarmType = (p) => qpost('/alarmType', p)
// export const putAlarmType = (p) => qput('/alarmType', p)
// export const removeAlarmType = (p) => remove('/alarmType', p)

/** 公司 */
export const getCompany = (p) => get('/company', p)
export const addCompany = (p) => post('/company', p)
export const putCompany = (p) => qput('/company', p)
export const removeCompany = (p) => remove('/company', p)

/** 联系人 */
export const getConcact = (p) => get('/contact', p)
export const addConcact = (p) => qapost('/contact', p)
export const putConcact = (p) => qput('/contact', p)
export const removeConcact = (p) => remove('/contact', p)

/**部门 */
export const getDepartmentTree = (p) => get('/department/tree', p)
export const getDepartment = (p) => get('/department', p)
export const addDepartment = (p) => qapost('/department', p)
export const putDepartment = (p) => qput('/department', p)
// export const qputDepartment = p => qput("/department", p);
export const removeDepartment = (p) => remove('/department', p)

/** 控制器 */
export const getDev = (p) => get('/dev', p)
export const getDevAndProneNum = (p) => get('/dev/andProbeNum', p)
export const addDev = (p) => qpost('/dev', p)
export const putDev = (p) => qput('/dev', p)
export const removeDev = (p) => qpost('/dev/real', p)
/** 前台没逻辑控制器 */
export const getProbeDev = (p) => get('/dev/probeId', p)

/** 数据 */
export const getDevData = (p) => get('/devData', p)
export const getDevDataReverse = (p) => get('/devData/reverse', p)
export const addDevData = (p) => qpost('/devData', p)
export const putDevData = (p) => qput('/devData', p)
export const removeDevData = (p) => remove('/devData', p)

/** 设备 */
export const getDevAlarmRecord = (p) => get('/devAlarm', p)
export const getDevAlarmList = (p) => get('/dev/alarm', p)
export const getDevTableList = (p) => get('/deviceTable', p)
// export const removeDevAlarm = (p) => qpost('/devAlarm', p)
export const removeDevAlarm = (p) => remove('/devAlarm', p)

/** 前台没逻辑数据 */
export const checkDevTable = (p) => get('/deviceTable/checkBind', p)
export const addDevTable = (p) => qpost('/deviceTable', p)
export const putDevTable = (p) => qput('/deviceTable', p)
export const removeDevTable = (p) => remove('/deviceTable', p)
export const setSSC = (p) => qpost('ssc', p)

/** 设备探测器关联 */
export const getDevProbe = (p) => qget('/devProbe', p)
export const addDevProbe = (p) => post('/devProbe', p)
export const putDevProbe = (p) => qput('/devProbe', p)
export const removeDevProbe = (p) => remove('/devProbe', p)

/** 气体类型 */
export const getGases = (p) => get('/gases', p)
export const addGases = (p) => qpost('/gases', p)
export const putGases = (p) => qput('/gases', p)
export const removeGases = (p) => remove('/gases', p)

/** 探测器 */
/** 后台没逻辑 */
export const getProbe = (p) => get('/probe', p) //实时数据
export const getProbeAlarm = (p) => get('/probe/alarm', p)
export const addProbe = (p) => qpost('/probe', p)
export const addProbeAndBind = (p) => qpost('/probe/anb', p)
export const putProbe = (p) => qput('/probe', p)
export const removeProbe = (p) => remove('/probe', p)

/** 用户功能 */
export const login = (p) => qpost('/user/login', p)
export const logout = () => qpost('/user/logout')
export const getUserTree = (p) => get('/user/tree', p)
export const getUser = (p) => get('/user', p)
export const handleRegiset = (p) => qpost('/user/register', p)
export const resetPwd = (p) => qpost('/user/reset', p)
export const regisDev = (p) => qpost('/user/registerDev', p)
export const addUser = (p) => qapost('/user', p)
export const putUser = (p) => qput('/user', p)
export const delUser = (p) => remove('/user', p)
export const editPwd = (p) => qpost('/user/changePwd', p)
export const forgetPwd = (p) => qpost('/user/forget', p)

/** 升级文件 */
export const getUpList = (p) => get('/upFile', p)
export const addUp = (p) => qpost('/upFile', p)
export const modifyUp = (p) => qput('/upFile', p)
export const removeUp = (p) => remove('/upFile', p)

/** 消息 */
export const getMsgList = (p) => get('/message', p)
export const addMsg = (p) => qpost('/message', p)
export const modifyMsg = (p) => qput('/message', p)
export const removeMsg = (p) => remove('/message', p)

/** 消息类型 */
export const getMsgTypeList = (p) => get('/messageType', p)
export const addMsgType = (p) => qpost('/messageType', p)
export const modifyMsgType = (p) => qput('/messageType', p)
export const removeMsgType = (p) => remove('/messageType', p)

/** 树形查询 */
export const getTreeDnd = (p) => get('/tree/dnd', p)

/** 角色 */
export const getRole = (p) => get('/role', p)
export const getPermissions = (p) => get('/permissions', p)
